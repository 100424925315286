import styled from 'styled-components';

export const MyDescriptionContainer = styled.div`
  min-height: 42px;
  display: flex;
  align-items: initial;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 14px;
    margin: 5px 0;
    line-height: 150%;
  }

  .success-color {
    color: #4BB543;
  }

  .error-color {
    color: #CC0000;
  }

  @media (max-width: 576px) {
    min-height: 0;
  }
`;
