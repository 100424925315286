import styled from 'styled-components';

export const WrapperContainer = styled.div`
  width: 100%;
  ${props => props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
  margin: 15px 0;

  .MuiInputBase-input {
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .MuiInputLabel-outlined {
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
  }

  .MuiOutlinedInput-inputMarginDense {
    padding-top: 13px;
    padding-bottom: 13px;
    height: auto;
    min-height: 0;
  }

  @media (max-width: 576px) {
    margin: 8px 0;
  }
`;