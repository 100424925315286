import React from 'react';
import {
  Button
} from '@material-ui/core';
import WrapWithContainer from '../CommonWrapper';
import { MyButtonContainer } from './style';

const MyButton = ({ label, type, buttonClassName, children, ...otherProps }) => {
  return (
    <MyButtonContainer>
      <Button variant="contained" className={`${type ? `btn-${type}` : ''} btn-transition-none ${buttonClassName}`} {...otherProps}>
        {children || label}
      </Button>
    </MyButtonContainer>
  );
};

export default WrapWithContainer(MyButton);