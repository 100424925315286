import styled from 'styled-components';

export const MyCardContainer = styled.div`
  .MuiCard-root {
    max-width: ${props => props.maxWidth ? `${props.maxWidth}` : '1020px'};
  }
  
  .card--content-wrapper {
    margin: 0;
  }
`;

export const MyCardSubContainer = styled.div`
  .sub-card--content-wrapper {
    padding: 0 15px;
  }
`

export const MyCardRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  ${props => props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
  ${props => props.direction ? `flex-direction: ${props.direction};` : ''}

  & > div {
    padding: ${props => props.direction === 'column' ? '0' : '0 10px'};
    width: 100%;

    &:nth-child(1) {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.card-row--fit-content {
      width: fit-content;
    }

    &.card-row--fill-rest {
      flex: 1;
    }
  }

  @media (max-width: ${props => props.responsivePoint}px) {
    flex-direction: column;
    margin: 0;

    & > div {
      padding: 0;
      max-width: 100%;
    }
  }
`;
