import styled from 'styled-components';

export const MyButtonContainer = styled.div`
  .MuiButton-root {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    height: 54px;
    font-weight: 600;
    span {
      pointer-events: none;
    }
    svg {
      pointer-events: none;
    }
  }
`;
