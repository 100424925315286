import React from 'react';
import { MyDescriptionContainer } from './style';
import WrapWithContainer from '../CommonWrapper';

const MyDescription = ({ children, ...otherProps }) => {
  return (
    <MyDescriptionContainer {...otherProps}>
      {children}
    </MyDescriptionContainer>
  );
};

export default WrapWithContainer(MyDescription);
